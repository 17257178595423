import * as React from "react";
import Seo from "../components/seo";
import OnboardingLayout from "../components/Layout/OnboardingLayout";
import { I18n } from "react-redux-i18n";
import ExpiredPasswordComponent from "../components/Authentication/ExpiredPassword";
import { useEffect } from "react";
import { loginPath } from "../utils/auth";
import { navigate, PageProps } from "gatsby";
import { clearAuthenticationState } from "../redux/actions/authActions";
import { useCurrentTheme } from "../utils/hooks";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/selectors/i18nSelectors";

const ExpiredPasswordPage = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);
  const userId = params.get("userId");
  const currentTheme = useCurrentTheme();
  const currentLanguage = useSelector(selectLanguage);
  const currLang = React.useRef(currentLanguage || "");

  useEffect(() => {
    if (currLang.current !== currentLanguage) {
      currLang.current = currentLanguage;
    }
  }, [currentLanguage]);

  useEffect(() => {
    clearAuthenticationState();
    if (!userId) {
      void navigate(loginPath);
    }
  }, [userId]);

  return (
    <div style={{ height: "100vh" }} className={currentTheme.themeName}>
      <ThemeProvider theme={currentTheme.theme}>
        <OnboardingLayout>
          <Seo title={I18n.t("Common.seo.resetPassword")} />
          <ExpiredPasswordComponent userId={userId} />
        </OnboardingLayout>
      </ThemeProvider>
    </div>
  );
};

export default ExpiredPasswordPage;
